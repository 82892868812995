.footer {
    padding: 1.125rem 0;
    text-align: center;

    .linkedInIcon:hover{
        color: #0B66C2 !important;
    }
    .githubIcon:hover{
        color: #75147C !important;
    }
    .googleIcon:hover{
        color: #FFBB00 !important;
    }
    
    .btn-round-linkedIn:hover{
        border-color: #0B66C2 !important;
    }
    .btn-round-github:hover{
        border-color: #75147C !important;
    }
    .btn-round-google:hover{
        border-color: #FFBB00 !important;
    }

    .footer-btn-wrapper{
        margin-top: -25px!important;
    }

    .footer-fu-wrapper{
        margin-top: -30px!important;
    }

    .cageCopy{
        cursor: pointer;
        font-size: 1.2rem;
    }
    .cageCopy:hover{
        color: theme-color("primary");
        font-weight: bolder;
    }
    .ueiCopy{
        cursor: pointer;
        font-size: 1.2rem;
    }
    .ueiCopy:hover{
        color: theme-color("primary");
        font-weight: bolder;
    }


    &.footer-default{
        background-color: #f2f2f2;
    }

    &.footer-simple{
        background: $transparent-bg;
    }

    nav{
        display: inline-block;
        float: left;
    }

    .content{
        text-align: left;
    }

    .nav-link{
        display: inline;
    }

    &.footer-big{
        padding: 30px 0;

        .social-feed i{
            font-size: 20px;
            float: left;
            padding-right: 10px;
            color: $white;
        }

        .gallery-feed img{
            width: 20%;
            margin-right: 5%;
            margin-bottom: 5%;
            float: left;
        }

        hr{
          width: 100%;
        }
    }

    .footer-brand{
        float: left;
        font-size: 0.875rem;
        line-height: 44px;
    }



    ul{
        margin-bottom: 0;
        padding: 0;
        list-style: none;

        &:not(.links-vertical){
            line-height: 3.1;
        }

        &.links-vertical{
            line-height: 1.6;

            li{
                display: block;
                margin-left: -5px;
                margin-right: -5px;
                margin-bottom: 0px;

                a{
                    padding: 5px;
                    display: block;
                }
            }
        }

        li{
            display: inline-block;

            a{
                color: inherit;
                padding: 13px $padding-base-vertical;
                font-size: $font-size-sm;
                text-transform: uppercase;
                text-decoration: none;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    .social-buttons {
      text-align: center;
        a,
        .btn{
            margin: 0;
        }
    }

    .pull-center{
        display: inline-block;
        float: none;
    }

    .copyright{
        font-size: $font-size-sm;
        line-height: 3.5;
    }

    &:after{
        display: table;
        clear: both;
        content: " ";
    }
}

@media screen and (max-width: 768px) {
  footer .title{
    text-align: center !important;
  }

  .footer .btn-wrapper.profile{
    text-align: center !important;
  }
}
