
html {
  scroll-behavior: smooth;
}


.modal-dialog{
  border-radius: 10px;
  border-color: #e14eca;

}
.modal-content{
  background-color: #222a42;
  border-radius: 10px;
  border-color: #e14eca;

}
.blog-wrapper{
  padding: 30px;
  border-radius: 10px;
  border-color: #e14eca;
  display: flex;
  flex-direction: column;
}

.blog-outer-click{
  cursor: pointer!important;
}

.blog-content p{
  font-size: 1.2rem;
}

.blogs-container{
  // margin: 0;
  display: flex!important;
  justify-content: center!important;
  max-width: 100%;
}

.blogs-5{
  display: flex!important;
  justify-content: center;
  width: 100%;
}

.blogs-container>.row{
  display: flex!important;
  justify-content: center;
  width: 100%;
}

.blog-page-container{
background-image: url(../../img/space-background.jpg);
background-attachment: fixed;
}

.blog-sub-text{
  color: whitesmoke!important;
}

.close-blog-modal{
  align-self: center;
}

.blog-title-tag-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.blog-title-tag-container{
  color: white!important;
}
.blog-title-placeholder{
  min-width: 20%;
}

.blog-tag-badge{
  text-transform: capitalize;
}

.error-page {
  >.container {
    text-align: center;
    color: $white;
    padding: 0 15px;
    width: 100%;
    max-width: 880px;

    .title {
      font-size: 12em;
      color: $white;
      letter-spacing: 14px;
      font-weight: $font-weight-extra-bold;
    }
  }
}

// multilevel dropdown

.dropdown.show {
  .dropdown-menu.show {
    .dropdown-item.dropdown-toggle + .dropdown-menu {
      &.show {
        left: 100% !important;

        li.show {
          .dropdown-menu.show {
            left: 100% !important;
          }
        }
      }
    }
  }
}

.navbar {
  .navbar-collapse {
    .nav-item {
      .dropdown-menu.show {
        .dropdown-toggle + .dropdown-menu {
          opacity: 0;
        }

        .dropdown-toggle.open + .dropdown-menu.show {
          opacity: 1;
        }
      }
    }
  }
}

.nav-up {
  top: -80px;
}
.nav-down {
  position: fixed;
  top: 0;
  transition: top 0.5s ease-in-out;
  width: 100%;
  z-index: 9999;
}

// documentation


.bd-docs {
  .form-group:after {
    top: 40px !important;
  }

    .slick-slide img {
      width: 100% !important;
  }
  .table tr td{
    color: $default;
  }
}
